import { TermsProps } from '../../@interface/terms'
import React from 'react'
import ApisTab from '../../components/ApisTab'

export const dataTermsApi: TermsProps = {
  title: 'Termos e Condições de APIs',
  subtitle: 'de APIs',
  update: '7 de Junho de 2024',
  about: {
    title: 'Termos e Condições de APIs',
    introduction: `
      Este documento contém os termos gerais e condições de serviços que regula a disponibilização de ferramentas, conteúdo e serviços pela CORA TECNOLOGIA LTDA. por meio de Interface de Programação de Aplicações (APIs), para gerenciar e facilitar a integração entre plataformas (“Termos de Serviços da API”).<br/><br/>
      Este Termos de Serviços de API deve ser interpretado em conjunto com nossa Política de Privacidade, que contém as regras e condições do uso de dados pessoais.<br/><br/>
      A aceitação destes Termos de Serviços de API é absolutamente indispensável ao uso da(s) API(s) e integração dos serviços relacionados à Conta Cora (“Serviços Cora”).<br/><br/>
      CASO VOCÊ TENHA QUALQUER DÚVIDA SOBRE OS TERMOS DE SERVIÇO DA API, entre em contato conosco através dos nossos Canais de Contato:<br/><br/>
      Suporte Técnico: <a href="mailto:suporteapi@cora.com.br">suporteapi@cora.com.br</a><br/>
      Jurídico: <a href="mailto:juridico@cora.com.br">juridico@cora.com.br</a><br/><br/>
      Caso NÃO concorde com todos estes termos, você NÃO deverá realizar o aceite destes Termos de Serviços de API e não poderá fazer uso da(s) API(s) para integração dos Serviços Cora.<br/><br/>
      Você pode se recusar ao tratamento de dados pessoais. Destacamos, contudo, que, nesse caso, não será possível realizar o aceite destes Termos de Serviços de API e fazer uso da(s) API(s) para integração dos Serviços Cora.
    `,
  },
  section: [
    {
      list: [
        {
          head: 'Natureza jurídica',
          items: [
            {
              item:
                'Este Termo de Uso é um contrato firmado entre você, de agora em diante denominado como “Empresa”, “Parceiro” ou “Usuário”, e a CORA TECNOLOGIA LTDA., empresa cadastrada no CNPJ sob nº 34.052.649/0001-78 com sede no município de São Paulo, capital do Estado de São Paulo, a Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação, São Paulo/SP, e de agora em diante denominada simplesmente de CORA. ',
            },
          ],
        },
        {
          head: 'Informação',
          items: [
            {
              item: `
                Este Termos de Serviços da API rege a disponibilização pela CORA da possibilidade de:<br/><br/>
                a) integração entre os Serviços Cora e plataformas de Empresas para o uso e benefício do cliente final da Empresa, denominada como “Modalidade Parceria de Integração”;<br/><br/>
                b) integração entre os Serviços Cora e o sistema da Empresa, para seu próprio uso , denominada como “API Direta”
              `,
              child: [
                {
                  item:
                    'A(s) API(s) possibilita(m) a integração segura para, a depender do seu tipo, se Direta ou Indireta: (i) promover a facilitação no processo de abertura da Conta Cora de novos clientes; (ii) autorizar e autenticar contas de terceiros (clientes) para um determinado escopo; (iii) integrar a gestão de contas de terceiros (clientes); (iv) possibilitar a emissão e gestão de boletos; (v) possibilitar a iniciação de pagamentos e transferências;  e (vi) consulta de extratos bancários.',
                },
              ],
            },
            {
              item:
                'Os presentes Termos de Serviços da API e os direitos e as obrigações aqui contidos são complementares aos <a href="https://www.cora.com.br/termos-uso/" target="_blank">Termos e Condições Gerais de Uso</a> ("Termos de Uso"), que se aplicam a todos os titulares de Conta Cora , por referência. Termos em letras maiúsculas que não sejam definidos aqui terão suas definições estabelecidas em nossos <a href="https://www.cora.com.br/termos-uso/" target="_blank">Termos de Uso</a>.',
            },
            {
              item:
                'A sigla API corresponde às palavras em inglês “<span style="font-style: italic">Application Programming Interface</span>“. No português, “Interface de Programação de Aplicações” – trata-se de um conjunto de rotinas, protocolos e ferramentas para a construção de aplicações de software que permite o intercâmbio de informações entre diferentes linguagens de programação e sistemas.',
            },
          ],
        },
        {
          head: 'APIs disponíveis',
          items: [
            {
              item: (
                <>
                  Na tabela abaixo seguem as API(s) disponíveis:
                  <br />
                  <ApisTab />
                </>
              ),
              isReactNode: true,
            },
            {
              item:
                'As despesas necessárias à execução da(s) API(s) e eventuais adaptações na plataforma da Empresa para compatibilização da(s) API(s) e efetiva integração serão de responsabilidade da Empresa.',
              child: [
                {
                  item: `
                    Para a utilização das API(s) aqui descritas serão cobradas tarifas e taxas por produto e/ou serviço usado pela integração, conforme segue:<br/><br/>
                    <div class="table-privacy">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Transferência</b>
                            </td>
                            <td>
                              TED (Transferência para outro banco)
                            </td>
                            <td>
                              R$ 2,00 por TED realizada
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">
                              <b>Boleto</b>
                            </td>
                            <td>
                              Compensação de boletos por código de barras
                            </td>
                            <td>
                              R$ 1,70 por boleto compensado
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Compensação de boletos por QR Code PIX (vide cláusula 3.2.2.)
                            </td>
                            <td>
                              1% do valor do boleto com um valor máximo de até R$ 0,50 por boleto compensado
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Cancelamento de boleto gerado via API (vide cláusula 3.2.3.)
                            </td>
                            <td>
                              R$ 0,30 por boleto cancelado
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Pix</b>
                            </td>
                            <td>Compensação de QR code Pix puro (sem um boleto associado) </td>
                            <td>1% do valor do QR code com um valor máximo de até R$ 0,50 por QR code compensado</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  `,
                },
              ],
            },
            {
              item:
                'A CORA NÃO ESTÁ OBRIGADA A FORNECER NENHUM SERVIÇO DE SUPORTE PARA EXECUÇÃO DA(S) API(s).',
            },
            {
              item:
                'A Empresa poderá apresentar para a Cora sugestões de modificações na(s) API(s). A CORA poderá incorporar tais modificações e informações adicionais a seu exclusivo critério, nada sendo devido à Empresa por tal decisão.',
            },
          ],
        },
        {
          head: 'Aceitação de termos e políticas',
          items: [
            {
              item:
                'Ao clicar NO BOTÃO "EU CONCORDO" para integrar a plataforma da Empresa aos Serviços Cora e fazer uso da(s) API(s), o Usuário reconhece que analisou e aceitou as condições de uso e confirma que concorda com este Termos de Serviços da API.',
            },
          ],
        },
      ],
    },
    {
      title: 'Detalhes dos Serviços e Condições para uso da(s) API(s)',
      list: [
        {
          head: 'Realizando o cadastro aos serviços da API',
          items: [
            {
              item:
                'Para integração entre os Serviços Cora e plataformas de Empresas, por meio da(s) API(s), será necessário: (i) ser Empresa nos termos definidos nos <a href="https://www.cora.com.br/termos-uso/" target="_blank">Termos de Uso</a> como “Sua Empresa”, com cadastro ativo, contrato de parceria ou prestação de serviço relacionados à Conta Cora, ou análogo, vigente; (ii) realizar cadastro, por canal especificamente estabelecido para tanto, manifestando formalmente interesse ao acesso às funcionalidades e documentação de APIs; (iii) obter aprovação, a exclusivo critério da CORA, para a integração entre as plataformas; (iv) receber acessos e permissões para executar a(s) API(s).',
            },
            {
              item:
                'O Usuário está ciente que, a partir do cadastro de interesse ao acesso às funcionalidades e documentação de APIs e adesão ao presente Termos de Serviços da API, a CORA poderá pedir documentos adicionais, se achar necessário, para o procedimento de validação do cadastro e autorização de uso da(s) API(s), inclusive, mas não limitado a apresentação de política de segurança cibernética vigente na Empresa/Parceiro, bem como demonstrar tecnicamente protocolos de segurança.',
            },
            {
              item:
                'Após o cadastro de interesse ao acesso às funcionalidades e documentação de APIs da Empresa pelo Usuário, a CORA poderá exigir, a qualquer momento, que sejam enviadas as versões digitalizadas e/ou originais de documentos relativos aos dados pessoais cadastrados.',
            },
            {
              item:
                'Após a informação dos dados e/ou documentos solicitados aos Usuários interessados ao acesso às funcionalidades e documentação de APIs, estes deverão, para finalização do cadastro, declarar o seu expresso aceite e concordância com estes Termos de Serviços da API.',
            },
            {
              item:
                'Com o aceite a este Termos de Serviços da API, a Empresa, por seu Usuário, declara estar ciente e possuir permissão e consentimento específico e expresso, na forma exigida pela legislação vigente, para autorizar, expressamente, a verificação, pela CORA, das informações por ele declaradas, podendo esta verificação ser feita por meio de pesquisas em bases públicas e/ou privadas, bem como reconhece expressamente que:',
              child: [
                {
                  item:
                    'A CORA poderá enviar as informações fornecidas para Parceiras e para outras entidades do grupo CORA, em conformidade com a nossa Política de Privacidade. Tais informações poderão ser usadas por entidades do grupo CORA, pelas Parceiras e por outras instituições a elas ligadas para verificar a adequação à integração pretendida, incluindo, adequação técnica, econômica e financeira, entre outros fatores relevantes para fins de avaliação unilateral conduzida pela CORA. A CORA, Parceiras e entidades do grupo CORA poderão entrar em contato com a Empresa, através das formas de contato informadas ao realizar o cadastro de interesse no acesso às funcionalidades e documentação de APIs;',
                },
                {
                  item:
                    'Autoriza o acesso da CORA, Parceiras ou de entidades do grupo CORA, conforme aplicável, às informações que lhe são relativas e que constem do Sistema de Informações de Crédito do Banco Central (“SCR”), dos órgãos de proteção ao crédito, de bases de dados públicas e privadas, conforme aplicável, e demais fontes que possam auxiliar na aprovação, a exclusivo critério da CORA, para a integração entre as plataformas; ',
                },
                {
                  item:
                    'Autoriza a execução de procedimentos de Know Your Partner (“KYP”), inclusive aqueles dispostos nos termos das leis anticorrupção, antiterrorismo, contra a lavagem de capitais e a evasão de divisas, nos termos da regulamentação bancária aplicável e sempre que necessário ou adequado.',
                },
                {
                  item:
                    'Autoriza a CORA, Parceiras ou entidades do grupo CORA a realizar verificações adicionais de identidade e indícios de fraude que poderiam ser cometidas pela Empresa, Usuário, outras pessoas físicas ou representantes legais da Empresa (como proprietário, sócio ou diretor), assim como outros membros ou diretores da Empresa, podendo se utilizar como referência de crédito agências, como por exemplo: SPC, Serasa Experian, Boa Vista, Equifax e qualquer outro terceiro parceiro, assim como ferramentas desenvolvidas pela própria CORA.',
                },
              ],
            },
            {
              item:
                'Após a pesquisa e verificação das informações mencionadas acima, a CORA poderá, a seu único e exclusivo critério, aprovar a integração entre as plataformas e autorizar acesso para executar a(s) API(s). Nesse caso, o Usuário será informado através das próprias Aplicações ou por e-mail e o Usuário deverá encaminhar os documentos solicitados, caso este procedimento não tenha sido realizado anteriormente.',
            },
            {
              item:
                'Note que, ainda que o Usuário realize o cadastro de interesse ao acesso às funcionalidades e documentação de APIs, a CORA poderá negá-lo e não aprovar a integração entre as plataformas e não autorizar acesso para executar a(s) API(s). Neste caso, o Usuário poderá realizar um novo cadastro após o período determinado pela CORA.',
            },
            {
              item:
                'A aprovação da integração entre as plataformas e autorização de acesso para executar a(s) API(s) será válida pelo período de 12 (doze) meses, podendo ser prorrogável por igual período, sendo esta prorrogação tácita quando nenhuma das Partes manifestar contrariamente, no período de 30 (trinta) dias antes do vencimento do presente.',
            },
          ],
        },
        {
          head: 'Licença limitada',
          items: [
            {
              item: `
                A Empresa, ao ser aprovada para integração das plataformas e autorizada a acessar e executar a(s) API(s), recebe uma licença limitada, não transferível, não sublicenciável, não exclusiva, livre de royalties e revogável para desenvolver, testar, compatibilizar e integrar a plataforma da Empresa e para distribuir ou permitir o acesso à sua integração das APIs dentro da plataforma da Empresa para usuários finais da plataforma, em conformidade com os <a href="https://www.cora.com.br/termos-uso/" target="_blank">Termos de Uso</a>, incluindo, sem limitação, os presentes Termos de Serviços da API, e conforme o permitido em todas as leis, regras e regulamentos locais, estaduais, provinciais, nacionais e outros.<br/><br/>
                Você reconhece e concorda que a CORA concede uma licença apenas para uso e desta forma não lhe transfere os direitos sobre a API e Aplicações. A venda, transferência, modificação, engenharia reversa ou distribuição bem como a cópia de textos, imagens ou quaisquer partes da(s) API(S) e Aplicações é expressamente proibida.
              `,
            },
          ],
        },
        {
          head: 'Perda da licença de acesso ao serviço da API',
          items: [
            {
              item:
                'A CORA poderá, a qualquer momento, com ou sem aviso prévio, rescindir o acesso, rescindir a licença e descontinuar o uso de toda(a) a(s) API(s) em razão de: (i) violação ou infração deste Termo de Serviço de API, incluindo, sem limitação, os <a href="https://www.cora.com.br/termos-uso/" target="_blank">Termos de Uso</a>; (ii) uso indevido, não autorizado ou abuso da(s) API(s) e Aplicações, e (iii) violar leis e regulamentos.',
            },
            {
              item:
                'A CORA poderá, ainda, bloquear a API e Aplicações se houver o compartilhamento da senha de acesso. Além disso, a Empresa será responsável por todos os gastos e prejuízos decorrentes de eventual utilização dos seus dados de autenticação por terceiros.',
            },
          ],
        },
      ],
    },
    {
      title: 'Política de Privacidade',
      bgColor: '#F0F4F8',
      list: [
        {
          head: 'Obtenção dos dados e informações',
          items: [
            {
              item:
                'Os dados e informações (sejam eles pessoais ou anonimizados) serão obtidos quando o usuário: (a) Passar a executar a(s) API(s); ou (b) Interagir com as Aplicações, fornecendo as informações voluntariamente ou involuntariamente;',
            },
          ],
        },
        {
          head: 'Uso dos dados e informações',
          items: [
            {
              item: `
                A Empresa, por seu Usuário, aceita de maneira expressa, consciente e voluntária que as suas informações submetidas à CORA (“Dados Pessoais” e/ou “Dados Pessoais Sensíveis”), sejam recompiladas, armazenadas para sua utilização pela CORA. A CORA poderá utilizar as informações coletadas, além das hipóteses previstas na Política de Privacidade, para:<br/><br/>
                (i) Aplicar estes Termos de Serviços da API, , incluindo, sem limitação, os <a href="https://www.cora.com.br/termos-uso/" target="_blank">Termos de Uso</a>;<br/>
                (ii) Responder a eventuais dúvidas e solicitações dos Usuários e clientes;<br/>
                (iii) Cumprir ordem legal ou judicial;<br/>
                (iv) Constituir, defender ou exercer regularmente direitos em âmbito judicial ou administrativo;<br/>
                (v) Garantir a segurança dos Usuários;<br/>
                (vi) Manter atualizados os cadastros dos Usuários para fins de contato por telefone, correio eletrônico ou por outros meios de comunicação;<br/>
                (vii) direcionar anúncios aos titulares dos Dados e a terceiros de quaisquer espécies, através de quaisquer meios ou canais, inclusive, mas não limitado, a meios eletrônicos, sobre parcerias da CORA, serviços, ainda que exclusivos, dos parceiros da CORA e campanhas relacionadas às Aplicações.<br/>
                (viii) Verificar, comprovar ou validar os dados entregues, <br/>
                (ix) Estudar e analisar a informação fornecida para o acompanhamento e melhoramento dos produtos e do serviço, <br/>
                (x) Entregar e transmitir a informação recolhida a terceiros com os que a CORA mantenha contratos comerciais ou de armazenamento e administração dos dados pessoais, sob os padrões de segurança e confidencialidade aos quais CORA está obrigada de acordo com o conteúdo das leis pertinentes; <br/>
                (xi) Transmitir dados em nível nacional ou internacional para fornecedores com os quais a CORA desenvolve atividades em conformidade com seu objeto social. Da mesma forma, uma transmissão pode ser feita para os aliados/parceiros estratégicos da empresa para realizar atividades de marketing, publicidade e promoções associadas ao objeto social; Tudo isso de acordo com as disposições da legislação brasileira. <br/>
                (xii) Juntar, possuir, manusear e utilizar a informação recebida pelo CORA para realizar a vinculação como contratante, <br/>
                (xiii) Juntar, possuir, manusear e utilizar a informação para realizar controle e prevenção de fraude, controle e prevenção de lavagem de ativos e financiamento do terrorismo, <br/>
                (xiv) Celebração, execução e gestão das Ofertas de Crédito, outras propostas de negócios e contratos objeto dos serviços prestados,

              `,
            },
            {
              item:
                'A quantidade e o tipo de Dados Pessoais coletados pela CORA variam conforme o uso da Plataforma pelo usuário. Para esclarecer essas questões, por favor consulte a nossa Política de Privacidade.',
            },
            {
              item:
                'A Empresa, por seu Usuário, desde já declara estar ciente que a CORA não assume nenhuma responsabilidade em caso de roubo, perda, alteração ou uso indevido de suas informações pessoais, sendo as informações cadastrais protegidas por login e senha, para a segurança e privacidade dos usuários. Será de responsabilidade do usuário a proteção de tais dados contra a utilização de terceiros, sendo os atos praticados após sua identificação, na Plataforma, com login e senha considerados de exclusiva responsabilidade dos usuários.',
            },
            {
              item:
                'Os usuários comprometem-se a notificar a CORA imediatamente, e por meio seguro, a respeito de qualquer uso não autorizado da(s) API(s) e Aplicações, bem como seu acesso não autorizado por terceiros. A Empresa e o Usuário serão responsáveis pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a inclusão da senha, que deverá ser de conhecimento e propriedade exclusiva do usuário.',
            },
          ],
        },
        {
          head: 'Alterações, modificações e rescisões',
          items: [
            {
              item:
                'A CORA reserva-se no direito de, a qualquer tempo, modificar este termo seja incluindo, removendo ou alterando quaisquer de suas cláusulas. Tais modificações terão efeito imediato. Após publicadas tais alterações, ao continuar ou iniciar o uso das APIS você terá aceitado e concordado em cumprir os termos modificados. ',
            },
            {
              item:
                'A CORA pode, de tempos em tempos, modificar ou descontinuar (temporária ou permanentemente) a distribuição ou a atualização da(s) API(s). A CORA não é obrigada a fornecer nenhum serviço de suporte para esta(s) API(s). O usuário não poderá responsabilizar a CORA nem seus diretores, executivos, funcionários, afiliados, agentes, contratados ou licenciadores por quaisquer modificações, suspensões ou descontinuidade do aplicativo.',
            },
            {
              item:
                'A CORA se reserva ao direito de modificar, suspender, terminar ou descontinuar qualquer aspecto das Aplicações e API(S) a qualquer tempo, no todo ou em parte, independentemente de comunicado ou aviso prévio. Também poderemos impor limitações a certas características, funcionalidades ou serviços ou restringir qualquer acesso a partes ou à totalidade das Aplicações, API(S) e Serviços Cora, sem prévia notificação e sem que isso implique em qualquer responsabilidade.',
            },
            {
              item:
                'Em caso de descontinuação da(s) API(s) e/ou de alguma de suas funcionalidades, os usuários não terão direito a nenhum tipo de indenização.',
            },
            {
              item:
                'Quaisquer melhorias ou adições às API(s) estarão sujeitos a este Termo de Serviço de API, a não ser que estabeleçamos de outra forma. Podemos introduzir novos conjuntos específicos de Termo de Serviço de API específicos, conforme apropriado, ou emendar Termo de Serviço de API específicos existentes.',
            },
            {
              item:
                'Para a comodidade dos usuários a data da última revisão destes Termos e Condições de Uso foi incluída no topo desta página. Recomendamos que toda vez que acessá-los, o usuário fique atento às novas atualizações, pois sua participação na Plataforma estará vinculada também por quaisquer alterações destes Termos e Condições de Uso.',
            },
          ],
        },
        {
          head: 'Websites e recursos terceiros',
          items: [
            {
              item:
                'Em virtude de parcerias da CORA ou por acreditarmos que possa ser do interesse dos usuários, poderão se conectar às Aplicações, a exclusivo critério da CORA, outros websites e funcionalidades da internet, sem que isso signifique que esses websites sejam de propriedade ou operados por ela.',
            },
            {
              item:
                'A CORA não tem nenhum controle sobre tais websites, portanto, não será responsável ou obrigada, direta ou indiretamente, por qualquer dano ou perda causada ou alegada, com relação ao uso ou confiança em quaisquer de tais conteúdos, bens ou website disponíveis em ou por meio de qualquer tal website ou recurso.',
            },
          ],
        },
        {
          head: 'Propriedade intelectual',
          items: [
            {
              item:
                'Todos os direitos relativos às API(s) e Serviços Cora aqui descritos e/ou às Aplicações, incluindo suas funcionalidades, são de titularidade da CORA, protegido pela lei de direitos autorais e de propriedade industrial. ',
            },
            {
              item:
                'Todos os Anúncios, marcas, textos, conteúdos de Anunciantes e/ou terceiros veiculados através da Plataforma são de propriedade de seus respectivos titulares. ',
            },
            {
              item:
                'É expressamente proibida usar, copiar, reproduzir, modificar, publicar, transmitir, distribuir, executar, licenciar, vender ou explorar, para qualquer finalidade, qualquer conteúdo de titularidade de terceiros sem o consentimento prévio de referido titular. O presente Termo de Serviço de API não produz nenhum efeito de cessão, licença ou prerrogativa em relação aos produtos, ou, ainda, com relação às marcas ou sinais, nem mesmo a qualquer patente, registro, ou título de propriedade.',
            },
            {
              item:
                'Como regra, é necessária uma licença de uso de marca para usar as Marcas CORA. Este Termo de Serviço de API não conta como “permissão para usar” nenhuma marca comercial e não há casos de uso pré-aprovados de “código aberto”. ',
            },
            {
              item:
                'Como exceção, ou seja, sem a licença de marca, a Empresa autorizada a executar (as) API(s), deverá manter em todos os materiais produzidos e todos os ambientes integrados em razão da execução das API(s) o termo “Powered by CORA”, e/ou o logotipo CORA, o qual deverá, necessariamente, ao ser clicado, redirecionar para a home page da CORA. ',
            },
            {
              item:
                'É expressamente proibido utilizar, sob qualquer forma ou pretexto, as Marcas CORA, suas reproduções parciais ou integrais ou ainda suas imitações, independentemente da destinação de tal uso.',
            },
            {
              item:
                'É vedada a utilização das Marcas ou de suas variações (incluindo erros de ortografia ou variações fonéticas) como nome de domínio ou parte de nome de domínio ou em qualquer nome de empresa, de qualquer tipo ou natureza, sob qualquer meio ou forma, inclusive por meio da criação de nomes de domínio ou e-mails. Todas as outras marcas, nomes de produtos, ou nomes de companhias que aparecem no site são de propriedade exclusiva de seus respectivos titulares.',
            },
          ],
        },
        {
          head: 'Plataforma da empresa',
          items: [
            {
              item:
                'A Empresa declara e garante à CORA que o uso do nome e/ou logotipo de seu produto, de sua empresa ou organização ("Seu Conteúdo") e sua plataforma por parte da CORA não violará os direitos de terceiros (por exemplo, qualquer propriedade intelectual ou outro direito de propriedade), ou qualquer lei aplicável. ',
            },
          ],
        },
        {
          head: 'Exclusividade',
          items: [
            {
              item:
                'Você reconhece e concorda que os presentes Termos de Serviços da API e a licença e outros direitos concedidos aqui não criam um relacionamento exclusivo com a CORA. A CORA poderá, por si mesma ou através de contratatos/permissões concedidas a outros, disponibilizar API(s) iguais ou semelhantes para qualquer propósito, incluindo, sem limitação, qualquer fim comercial.',
            },
          ],
        },
        {
          head: 'Isenção de garantias e limitações de responsabilidade',
          items: [
            {
              item:
                'A(s) API(s) estarão em contínuo desenvolvimento e pode conter erros e, por isso, o uso é fornecido “no estado em que se encontra” e sob risco do usuário final. Na extensão máxima permitida pela legislação aplicável a CORA e seus fornecedores isentam-se de quaisquer garantias e condições expressas ou implícitas incluindo, sem limitação, garantias de comercialização, adequação a um propósito específico, titularidade e não violação no que diz respeito ao aplicativo e qualquer um de seus componentes ou ainda à prestação ou não de serviços de suporte. A CORA não garante que a operação deste aplicativo seja contínua e sem defeitos. ',
            },
            {
              item:
                'Exceto pelo estabelecido neste documento não há outras garantias, condições ou promessas aos aplicativos, expressas ou implícitas, e todas essas garantias, condições e promessas podem ser excluídas de acordo com o que é permitido por lei sem prejuízo à CORA e seus colaboradores.',
            },
            {
              item:
                'A CORA não garante, declara ou assegura que o uso da(s) API(s) será ininterrupto ou livre de erros e você concorda que a CORA poderá remover por períodos indefinidos ou cancelar a(s) API(s) a qualquer momento sem que você seja avisado.',
            },
            {
              item:
                'A CORA não garante, declara nem assegura que a(s) API(s) esteja livre de perda, interrupção, ataque, vírus, interferência, pirataria ou outra invasão de segurança e isenta-se de qualquer responsabilidade em relação a essas questões. ',
            },
            {
              item:
                'A CORA não se responsabiliza por: (i) eventuais ações ou omissões de terceiros, ainda que tais terceiros sejam seus parceiros comerciais; (ii) atos praticados por Usuários que fizeram registros fraudulentos ou mediante falsa declaração; (iii) uso inadequado e/ou divulgação de dados pelos Usuários a terceiros; e/ou (iv) pela utilização de dados de terceiros sem qualquer autorização do respectivo titular. ',
            },
            {
              item:
                'A CORA, também, não se responsabiliza pelo conteúdo, funcionalidades, serviços ou segurança das informações quando realizado o acesso a sites e/ou aplicações de terceiros, ainda que o acesso seja feito por meio das Aplicações. Tais sites podem possuir seus próprios termos de uso e política de privacidade, completamente alheios aos termos e políticas das Aplicações. Em vista disso, devem sempre ser verificados os termos de uso e política de privacidade quando do acesso às páginas pertencentes a terceiros. Tais páginas podem possuir termos de uso e política de privacidade próprias, estando o seu uso e dados coletados sujeito a tais disposições, alheias ao controle ou responsabilidade da CORA.',
            },
            {
              item:
                '<b>AFORA AS RESPONSABILIDADES PREVISTAS NESTES TERMOS DE SERVIÇOS DA API, NÃO CONFERIMOS A VOCÊ NEM A QUALQUER USUÁRIO QUALQUER GARANTIA ADICIONAL, LIMITANDO-SE ÀS RESPONSABILIDADES AQUI DESCRITAS. NÃO GARANTIMOS, REEMBOLSAMOS, RESSARCIMOS OU INDENIZAMOS QUALQUER USUÁRIO, PARCEIRO OU TERCEIRO A RESPEITO DE QUAISQUER OUTRAS DISPOSIÇÕES QUE NÃO AS AQUI PREVISTAS ORIGINALMENTE. </b>',
            },
            {
              item:
                '<b>NÓS NÃO PRESTAMOS QUALQUER GARANTIA NO SENTIDO DE QUE (I) A(S) API(S) E OS SERVIÇOS CORA ATENDERÃO ESPECIFICAMENTE A SUAS NECESSIDADES; OU QUE (II) A(S) API(S) E OS SERVIÇOS CORA IRÃO GERAR QUALQUER PROVEITO ECONÔMICO OU RETORNO FINANCEIRO PARA VOCÊ.</b>',
            },
            {
              item:
                'É DE RESPONSABILIDADE DO EMPRESA: (I) MANTER SEGURO O AMBIENTE DE SEUS DISPOSITIVOS DE ACESSO, VALENDO-SE DE FERRAMENTAS ESPECÍFICAS PARA TANTO, TAIS COMO ANTIVÍRUS, FIREWALL, ENTRE OUTRAS, DE MODO A CONTRIBUIR PARA A PREVENÇÃO DE RISCOS ELETRÔNICOS; (II) UTILIZAR SISTEMAS OPERACIONAIS ATUALIZADOS E EFICIENTES PARA A PLENA UTILIZAÇÃO DA(S) API(s), APLICAÇÕES E DOS SERVIÇOS CORA E (III) EQUIPAR-SE E RESPONSABILIZAR-SE PELOS DISPOSITIVOS DE HARDWARE NECESSÁRIOS PARA O ACESSO À(s) API(s), APLICAÇÕES E SERVIÇOS CORA , BEM COMO PELO ACESSO DESSES À INTERNET.',
            },
            {
              item:
                'A CORA poderá ceder a presente relação contratual ou os direitos dela derivados a qualquer das empresas componentes do grupo econômico do qual faz parte, a seu exclusivo critério, bem como a quaisquer terceiros em razão de fusão, cisão, incorporação ou qualquer ato de reestruturação societária.  ',
            },
            {
              item:
                'Este Termos de Serviços da API não modifica, expressa ou tacitamente, qualquer instrumento por escrito anteriormente firmado entre a CORA, Empresa e usuários, assim como a concessão de acesso à(s) API(S) não modifica e/ou desconfigura a natureza jurídica de quaisquer relações estabelecidas.',
            },
            {
              item:
                'Nenhuma disposição deste Termos de Serviços da API deverá ser interpretada de modo a sugerir, implícita ou expressamente, que existe qualquer outro tipo de relação entre a CORA e a Empresa senão aquela mútua a independentemente estabelecida entre ambos por força contratual.',
            },
            {
              item:
                'Não é estabelecido, por força deste Termos de Serviços da API, qualquer tipo de joint-venture, franquia, associação, filial ou relação de emprego entre a CORA e a Empresa. A Empresa prestará os serviços de forma autônoma, respondendo por seus serviços perante consumidores e como único e exclusivo e empregador da mão de obra utilizada para a prestação dos serviços.',
            },
          ],
        },
        {
          head: 'Informações confidenciais',
          items: [
            {
              item:
                'Todo e qualquer dados e informações, sejam elas informações comerciais, financeiras, operacionais, técnicas, econômicas, contábeis, mercadológicas regulatórias, de marketing, de engenharia, de programação, jurídica ou de qualquer outra natureza, disponibilizadas através da(s) API(s) e nas Aplicações (“Conteúdo”) serão consideradas Informações Confidenciais reveladas ao Usuário e deverão ser guardadas em segredo, não devendo ser reveladas a terceiros ou utilizadas, sem a devida autorização da CORA.',
              child: [
                {
                  item:
                    'O Usuário obriga-se, neste ato, por si, não revelar, divulgar ou publicar na internet e em quaisquer redes sociais qualquer Informação Confidencial que não aquelas expressamente autorizadas pela CORA.',
                },
              ],
            },
            {
              item:
                'Caso o Usuário ou Empresa venha a ser legalmente obrigado a revelar qualquer conteúdo, dados e informações disponibilizadas, por qualquer juízo ou autoridade governamental competente, o Usuário deverá notificar em prazo razoável a CORA de tal ordem, para que este possa tomar as medidas cabíveis para resguardar os seus direitos ou dispensar o Usuário de cumprir tal ordem, a seu exclusivo critério, sendo que o Usuário deverá cooperar com a CORA nos esforços a esse propósito. Se, na falta de uma medida legal cabível ou de uma autorização para o não cumprimento da ordem judicial, o Usuário estiver obrigado a revelar qualquer Informação Confidencial, o Usuário revelará somente aquela parte da Informação Confidencial que for estritamente necessária para cumprir com tal ordem judicial, conforme orientação expressa da CORA. O Usuário cooperará com o(s) advogado(s) da CORA na obtenção das medidas legais ou outras garantias de que as informações fornecidas receberão tratamento confidencial',
            },
            {
              item:
                'Caso o Usuário ou a Empresa viole a presente cláusula, a Cora poderá tomar as medidas judiciais cabíveis, com fulcro nas disposições da Lei nº 9.279/96.',
            },
          ],
        },
        {
          head: 'Disposições gerais',
          items: [
            {
              item:
                'A tolerância de qualquer uma das Partes, em relação a eventuais infrações da outra, não importará em modificação contratual, novação ou renúncia a direito, devendo ser considerada mera liberalidade da citada Parte.',
            },
            {
              item:
                'Se qualquer uma das disposições do presente Termo for ou vier a tornar-se nula ou revelar-se omissa, tal nulidade ou omissão não afetará a validade das demais disposições deste contrato. Nesse caso, as Partes envidarão esforços no sentido de estabelecer normas que mais se aproximem, quanto ao resultado, da(s) disposição(ões) a ser(em) alterada(s) ou eliminada(s).',
            },
          ],
        },
        {
          head: 'Da legislação aplicável e foro',
          items: [
            {
              item:
                'Para dirimir quaisquer controvérsias oriundas do presente Instrumento, as partes elegem o Foro da Comarca da cidade de São Paulo – SP (renunciando a qualquer outro por mais privilegiado que seja), sendo aplicáveis as Leis da República Federativa do Brasil.',
            },
          ],
        },
      ],
    },
  ],
}
