import React from 'react'
import { useSwiper } from 'swiper/react'
import * as Styled from './styled'

const SwiperButton = (): JSX.Element => {
  const swiper = useSwiper()

  return (
    <Styled.SwiperButton>
      <Styled.BlurPrev />
      <Styled.PrevButton onClick={() => swiper.slidePrev()}></Styled.PrevButton>
      <Styled.BlurNext />
      <Styled.NextButton onClick={() => swiper.slideNext()}></Styled.NextButton>
    </Styled.SwiperButton>
  )
}

export default SwiperButton
