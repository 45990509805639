import styled from 'styled-components'
import { device } from '../../styles/breakpoints'

export const Container = styled.div`
  margin: 40px auto 0;
  display: grid;

  .swiper,
  .swiper-container {
    width: 100%;
  }

  .swiper-slide {
    width: auto;
    color: #6b7076;
    cursor: pointer;
    padding: 24px 24px 16px;
    font-size: 1.8rem;
    line-height: 3.2rem;

    &.active {
      color: #3b3b3b;
      font-weight: bold;
      border-bottom: 3px solid #3b3b3b;
    }

    &:first-of-type {
      margin-left: 36px;
    }

    &:last-of-type {
      margin-right: 36px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
`

export const Title = styled.p`
  color: black;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
`

export const Content = styled.div`
  padding: 63px 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 32px;

  @media ${device.laptop} {
    padding: 63px 32px;
  }
`

export const Card = styled.div`
  max-width: 340px;
  min-height: 390px;
`

export const CardHeader = styled.div`
  padding: 32px;
  background-color: rgba(254, 62, 109, 0.1);
  border-radius: 8px 8px 0 0;

  p {
    padding-left: 0;
    font-size: 1.6rem;
  }
`

export const HeaderLabel = styled.label`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: #d4265a;
  margin-bottom: 8px;
`

export const HeaderTitle = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: bold;
  color: #3b3b3b;
`

export const CardBody = styled.div`
  padding: 48px 32px;
  border-radius: 0 0 8px 8px;
  background-color: #f5f5f6;
  color: #6b7076;
  font-size: 1.6rem;
  line-height: 2.4rem;
  min-height: 230px;
`
