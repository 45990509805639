import React, { useState } from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'

import 'swiper/swiper-bundle.css'
import SwiperButton from '../ApisTabButton'

import { APIS_AVAILABLE } from './constants'
import * as Styled from './styled'

const ApisTab = (): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(APIS_AVAILABLE[0].id)
  const [currentTab, setCurrentTab] = useState<any>(APIS_AVAILABLE[0])

  const handleTabClick = (currentId: string) => {
    const updatedTab = APIS_AVAILABLE.find(api => api.id === currentId)
    setCurrentTab(updatedTab)
    setCurrentIndex(currentId)
  }

  return (
    <Styled.Container>
      <Swiper slidesPerView="auto" spaceBetween={0}>
        {APIS_AVAILABLE.map((api, index) => (
          <SwiperSlide
            key={api.id}
            virtualIndex={index}
            className={currentIndex === api.id ? 'active' : ''}
            onClick={() => handleTabClick(api.id)}
          >
            {api.title}
          </SwiperSlide>
        ))}
        <SwiperButton />
      </Swiper>

      <Styled.Content>
        {currentTab?.items?.map((item: any, index: any) => (
          <Styled.Card key={index}>
            <Styled.CardHeader>
              <Styled.HeaderLabel>API</Styled.HeaderLabel>
              <Styled.HeaderTitle>{item?.title}</Styled.HeaderTitle>
            </Styled.CardHeader>
            <Styled.CardBody>{item?.content}</Styled.CardBody>
          </Styled.Card>
        ))}
      </Styled.Content>
    </Styled.Container>
  )
}

export default ApisTab
