import React from 'react'

import TermsPages from '../partials/Terms/About'
import { dataTermsApi } from '../data/terms/terms-api'

const ApiTermsConditionsPage = (): JSX.Element => (
  <TermsPages data={dataTermsApi} linkCanonical="termos-e-condicoes-de-apis" />
)

export default ApiTermsConditionsPage
