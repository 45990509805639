import styled from 'styled-components'

export const SwiperButton = styled.div`
  button {
    background-color: #fbfbfb;
    border-radius: 50%;
    border: 1px solid #3b3b3b;
    color: #3b3b3b;
    cursor: pointer;
    height: 36px;
    position: absolute;
    top: 24px;
    width: 36px;
    z-index: 2;

    &:after {
      border-style: solid;
      content: "";
      height: 0;
      position: absolute;
      width: 0;
    }

    &:before {
      border-style: solid;
      content: "";
      height: 0;
      position: absolute;
      width: 0;
    }
  }

  span {
    height: 36px;
    position: absolute;
    top: 24px;
    width: 36px;
    z-index: 1;
  }
`

export const PrevButton = styled.button`
  left: 0;

  &:after {
    border-color: transparent #fafafa transparent transparent;
    border-width: 8px 8px 8px 0;
    left: 13px;
    top: 9px;
  }

  &:before {
    border-color: transparent #3b3b3b transparent transparent;
    border-width: 9px 9px 9px 0;
    left: 12px;
    top: 8px;
  }
`

export const NextButton = styled.button`
  right: 0;

  &:after {
    border-color: transparent transparent transparent #fafafa;
    border-width: 8px 0 8px 8px;
    left: 14px;
    top: 9px;
  }

  &:before {
    border-color: transparent transparent transparent #3b3b3b;
    border-width: 9px 0 9px 9px;
    left: 14px;
    top: 8px;
  }
`

export const BlurPrev = styled.span`
  background: linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(250,250,250,0) 100%);
  left: 35px;
`

export const BlurNext = styled.span`
  background: linear-gradient(90deg, rgba(250,250,250,0) 0%, rgba(250,250,250,1) 100%);
  right: 35px;
`
