export const APIS_AVAILABLE = [
  {
    id: 'AUTHENTICATION',
    title: 'Autenticação',
    items: [
      {
        title: 'Gerar token de autenticação Modalidade: client_credentials',
        content: 'API para gerar o token de autenticação na modalidade (grant_type) oauth2 Client Credentials. O token gerado é da própria conta e não carrega informações e autorizações de outros clientes.',
      },
      {
        title: 'Gerar token de autenticação Modalidade: authorization_code',
        content: 'API para gerar o token de autenticação na modalidade (grant_type) oauth2 Authorization Code. O token gerado permite ao parceiro fazer operações em nome do cliente que consentiu no escopo informado no fluxo de autorização.',
      },
      {
        title: 'Atualizar token de autenticação',
        content: 'API para atualizar o par de tokens gerados na autenticação. Por segurança, o Access Token gerado tem validade máxima de 24h. Essa API é utilizada para atualizar o acesso, sem que o cliente final precise autorizar novamente. Trabalhar na modalidade (grant_type) oauth2 Refresh Token.',
      },
    ],
  },
  {
    id: 'MANAGEMENT',
    title: 'Gestão',
    items: [
      {
        title: 'Consulta de extrato',
        content: 'API que retorna todas as operações realizadas pelo cliente em um determinado intervalo de tempo ou tipo de transação.',
      },
    ],
  },
  {
    id: 'BILLING_MANAGEMENT',
    title: 'Gestão de cobrança',
    items: [
      {
        title: 'Emissão de boleto',
        content: 'API de criação de uma nova cobrança na modalidade Boleto. Essa API retorna todos os dados do boleto e um documento já formatado em PDF com o código de barras e outras informações necessárias ao pagamento.',
      },
      {
        title: 'Emissão de boleto com qr-code PIX',
        content: 'API de criação de uma nova cobrança na modalidade Boleto e PIX. Essa API retorna todos os dados do boleto e um documento já formatado em PDF com o código de barras e um qr-code PIX e outras informações necessárias ao pagamento.',
      },
      {
        title: 'Consulta de detalhes do Boleto',
        content: 'API que detalha um determinado boleto gerado anteriormente pelo cliente na plataforma do Parceiro.',
      },
      {
        title: 'Consulta de boletos',
        content: 'API que retorna uma lista de faturas geradas anteriormente pelo cliente na plataforma do Parceiro, dada um determinado intervalo de tempo fornecido como parâmetro da consulta.',
      },
      {
        title: 'Cancelar Boletos',
        content: 'Cancela um determinado boleto gerado anteriormente pelo cliente na plataforma do Parceiro.',
      },
      {
        title: 'Editar notificações de cobrança',
        content: 'API para editar as notificações de cobrança que foram inseridas na API de Emissão de Boleto. As notificações de cobrança auxiliam na automatização das cobranças dos pagadores do Boleto.',
      },
    ],
  },
  {
    id: 'TRANSFERS',
    title: 'Transferências',
    items: [
      {
        title: 'Iniciação de Transferência (TED)',
        content: 'API para iniciar uma transferência. Por se tratar de saída de dinheiro, essa transação ficará com o estado de iniciada e precisará ser autorizada nos aplicativos para dispositivos móveis.',
      },
      {
        title: 'Consulta da lista de transferências (TED)',
        content: 'API que retorna uma lista de transferências iniciadas anteriormente pelo cliente na plataforma do Parceiro, dada um determinado intervalo de tempo fornecido como parâmetro da consulta.',
      },
    ],
  },
  {
    id: 'PAYMENTS',
    title: 'Pagamentos',
    items: [
      {
        title: 'Iniciação de Pagamentos',
        content: 'API para iniciar um pagamento de uma conta com código de barras (Boleto). Por se tratar de saída de dinheiro, essa transação ficará com o estado de iniciada e precisará ser autorizada nos aplicativos para dispositivos móveis.',
      },
      {
        title: 'Consulta da Lista de Pagamentos',
        content: 'API que retorna uma lista de pagamentos iniciados anteriormente pelo cliente na plataforma do Parceiro, dada um determinado intervalo de tempo fornecido como parâmetro da consulta.',
      },
    ],
  },
  {
    id: 'RECOMMENDATION',
    title: 'Indicação',
    items: [
      {
        title: 'Pré-cadastro',
        content: 'API para indicação de clientes para Cora. Essa API ajuda a vincular o cliente indicado ao Parceiro e fornece uma melhor experiência para o cliente final no processo de cadastro.',
      },
    ],
  },
  {
    id: 'WEBHOOK',
    title: 'Webhook',
    items: [
      {
        title: 'Registrar um endpoint',
        content: 'API para registro de um endpoint que será sensibilizado quando um determinado evento de interesse do Parceiro ocorrer. Pode ser utilizado para atualização dos estados do objeto de cobrança, por exemplo, indicando se um boleto foi pago, cancelado ou está atrasado.',
      },
      {
        title: 'Listar endpoints',
        content: 'API que retorna uma lista de endpoints cadastrados anteriormente pelo Parceiro. Retorna também, os detalhes do endpoints, como seus recursos e gatilhos configurados.',
      },
      {
        title: 'Excluir um endpoint',
        content: 'API para excluir um determinado endpoint cadastrado anteriormente pelo Parceiro.',
      },
    ],
  },
  {
    id: 'PLATFORM',
    title: 'Plataforma',
    items: [
      {
        title: 'Lista de Bancos',
        content: 'API auxiliar para utilização de outros produtos e serviços financeiros. Retorna uma lista de bancos registrados no Banco Central.',
      },
    ],
  },
]
